import { ProgressSpinner } from "primereact/progressspinner"
import styles from "../../styles/Loader.module.css"
import { gql } from "@apollo/client"
import moment from "moment"
import { DateTime } from "luxon"
import ImageWithLoader from "./ImageWithLoader"

export const staffNameInitials = (name) => {
    return name.first.charAt(0).toUpperCase() + name.last.charAt(0).toUpperCase()
}

export const getActualUTCTime = () => {
    return DateTime.utc().toISO()
}

export const sortListByDate = (list, dateField) => {
    list.sort((a, b) => {
        let dateA = DateTime.fromISO(a[dateField])
        let dateB = DateTime.fromISO(b[dateField])
        return dateB - dateA
    })
}

export function LoaderMedium() {
    return (
        <div className={styles.wrapperMed}>
            <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                animationDuration=".4s"
            />
        </div>
    )
}

export const findName = (id, list) => {
    const staff = list.find((staff) => staff.staff_id === id)
    return staff ? { first: staff.staff_name_first, last: staff.staff_name_last, full_name: staff.staff_full_name } : {
        first: "", last: "", full_name: ""
    }
}

export const getTimeAgo = (date) => {
    if (date != null && date !== "0001-01-01T00:00:00") {
        return moment.utc(date).local().fromNow()
    }
    return null
}

export const groupMessagesByDate = (messages) => {
    let groupedMessages = []
    let lastFromId = null
    let lastKey = null
    let lastGroupIndex = -1

    messages.forEach(message => {
        const relativeTime = moment(message.dateUpdated).fromNow()
        const key = `${relativeTime}_${message.fromId}`
        if (key === lastKey && lastFromId === message.fromId) {
            groupedMessages[lastGroupIndex].messages.push(message)
        } else {
            groupedMessages.push({
                key: key,
                messages: [message]
            })
            lastGroupIndex++
        }
        lastFromId = message.fromId
        lastKey = key
    })
    return groupedMessages
}

export const GET_STAFF_NAMES = gql`
  query getStaffNamesByIds($staff_id: ID!, $organization_id: ID!, $staff_ids: [ID!]!) {
    getStaffNamesByIds(staff_id:$staff_id, organization_id:$organization_id, staff_ids: $staff_ids) {
        data
    }
  }
`

export const InactiveStatuses = ["Inactive Discharged", "Inactive Referrals", "Kiosk Member"]

const displayBody = (body) => {
    //if body contains \r\n means it's a multiline message and should show it with line breaks
    if (body.includes("\r\n")) {
        return body.split("\r\n").map((line, i) => (
            <p key={i}>{line}</p>
        ))
    } else {
        return <p>{body}</p>
    }
}

export const transformMessage = (message) => {
    //if mge contains a twilio or gcp bucket link means it's an image and should show it as image
    let regex = ""
    if (message.body.includes("https://p.twil.io")) {
        regex = /https:\/\/p\.twil\.io\/\S+/g
    } else if (message.body.includes("https://storage.googleapis.com")) {
        regex = /https:\/\/storage\.googleapis\.com\/\S+/g
    }
    if (regex !== "") {
        let links = message.body.match(regex)
        let textWithoutLinks = message.body.replace(regex, '').trim()
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {displayBody(textWithoutLinks)}
                {links.map((link, i) => (
                    <ImageWithLoader aKey={i} src={link} width="150" />
                ))}
            </div>
        )
    } else {
        if (message.mediaUrl) {
            return (
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {displayBody(message.body)}
                    <ImageWithLoader src={message.mediaUrl} width="150" />
                </div>
            )
        }
        return displayBody(message.body)
    }
}
