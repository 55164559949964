import { useContext } from "react"
import { useQuery } from "@apollo/client"
import { Formik, Form as FormWrapper } from "formik"
import _ from "lodash"

// Context
import { UserContext } from "../../context/userContext"
import { FormContext } from "../../context/formContext"

// Comps
import BlockBuilder from "./BlockBuilder"

// Helpers
import validationSchema from "../../helpers/validationSchema"
import initialValues from "./initialValues"
import parseDatesInObj from "../../helpers/parseDatesInObj"

// Queries
import { GET_TEMPLATE_PAGE } from "./queries"

import styles from "../../styles/Form.module.css"
import FormLoader from "./FormLoader"

export default function FormContainer({
  blocks,
  globalValues,
  dataPropagation,
}) {
  const form = useContext(FormContext)
  const user = useContext(UserContext)

  const currentBlock = _.find(blocks, ["index", Number(form.currentPage)])

  const getTotalFields = () => {
    if (currentBlock) {
      const total_fields = Number(currentBlock.total_fields)
      return total_fields
    }
    return null
  }

  const { loading, error, data } = useQuery(GET_TEMPLATE_PAGE, {
    variables: {
      staff_id: 0,
      type: "system",
      participant_id: form.participant_id,
      page: form.currentPage,
      idStamp: form.idStamp,
      idTemplate: form.idTemplate,
      organization_id: user.organization_id,
    },
  })

  const total_fieldsSkeleton = getTotalFields()

  if (loading) return <FormLoader numberOfFields={total_fieldsSkeleton} />

  const blockName = currentBlock["block_name"]
  const blockValuesFromGlobal = globalValues[blockName]

  const dataPropagationForCurrentBlock = () => {
    const { block_name } = currentBlock
    if (data.template.values) {
      const dataParsed = JSON.parse(data.template.values)
      return parseDatesInObj(dataParsed[block_name])
    } else if (_.has(dataPropagation, block_name)) {
      return parseDatesInObj(dataPropagation[block_name])
    }
    return {}
  }

  const formValues = {
    ...initialValues(data),
    ...dataPropagationForCurrentBlock(),
    ...blockValuesFromGlobal,
  }

  return (
    <Formik
      key={blockName}
      initialValues={formValues}
      validationSchema={validationSchema(data.template.blocks)}
      validateOnMount={true}
    >
      <FormWrapper className={styles.form}>
        <BlockBuilder block={data.template.blocks[0]} />
      </FormWrapper>
    </Formik>
  )
}
